// extracted by mini-css-extract-plugin
export var background = "vodka-page-module--background--73371";
export var backgroundAnimation = "vodka-page-module--backgroundAnimation--3b1d5";
export var backgroundBox = "vodka-page-module--background-box--e0758";
export var content = "vodka-page-module--content--8f3ce";
export var errorBlink = "vodka-page-module--error-blink--6467b";
export var introBox = "vodka-page-module--intro-box--4f90a";
export var layout = "vodka-page-module--layout--a1e62";
export var productBox = "vodka-page-module--product-box--34c70";
export var secretList = "vodka-page-module--secret-list--61b39";
export var title = "vodka-page-module--title--e74d5";