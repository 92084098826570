import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';


import {
    layout,
    background,
    productBox,
    introBox,
    secretList
} from './vodka-page.module.scss';

import { IPage } from '../models/page.model';
import { IMainProduct } from '../models/main-product.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { ImageDataLike } from '../models/image-data-like.model';
import { getNodes } from '../utils/get-nodes';
import { getSecrets } from '../utils/get-secrets';
import { ISitePageContext } from '../models/site-page.model';

import MainLayout from '../layouts/main-layout';
import SecretList from '../components/organisms/secret-list';
import BackgroundRepeat from "../layouts/background-repeat";
import PageIntro from "../components/organisms/page-intro";
import ProductPreview from "../components/molecules/product-preview";


interface IVodkaPageProps {
    readonly data: {
        page: IPage | null;
        product: IMainProduct | null;
        allSecretImg: IQueryAllResult<ImageDataLike>;
    };
    readonly pageContext: ISitePageContext;
}

const VodkaPage: React.FC<IVodkaPageProps> = ({ data, pageContext }) => {
    const { t } = useI18next();
    const { page, product, allSecretImg } = data;
    const { site, introContent } = pageContext;
    const secretImages = getNodes(allSecretImg);
    const secrets = getSecrets(secretImages, 'vodka.secret.item.title', t);

    return (
        <MainLayout page={page} className={layout} siteKey={site}>
            <BackgroundRepeat className={background}>
                <div className={introBox}>
                    <PageIntro title={t("layout.page.intro.title.vodka")} subTitle={t("layout.page.intro.subTitle.vodka")} description={introContent} />
                    <SecretList
                        secrets={secrets}
                        className={secretList}
                    />
                </div>

                {product && <ProductPreview recipe={product} className={productBox} noBrandName additionalName={product.name}/>}
            </BackgroundRepeat>
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }

        product: strapiMainProduct(
            locale: { eq: $language }
            type: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...mainProductFields
        }

        allSecretImg: allFile(
            filter: {
                relativePath: {
                    in: [
                       "flavored-secret-05.png"
                        "flavored-secret-10.png"
                        "flavored-secret-13.png"
                        "flavored-secret-14.png"
                    ]
                }
            }
            sort: { fields: relativePath, order: ASC }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                }
            }
        }
    }
`;

export default VodkaPage;
